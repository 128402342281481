import { ITestimonials } from '@/types/ITestimonials';
import ImageBlock from '@/components/image-block/ImageBlock';
import Quote from '@/assets/Quote';

export default function TestimonialItem(props: ITestimonials) {
  const { title, textContent, authorName, authorPosition, authorProfileImg } = props;

  return (
    <div className='flex select-none flex-col gap-13 rounded-b-3.5xl rounded-tl-3.5xl border border-primary-aqua p-8 md:flex-row md:p-13 dark:bg-primary-black'>
      <ImageBlock
        asset={{ filename: authorProfileImg?.filename, alt: authorName }}
        width={250}
        height={250}
        className='mx-auto hidden lg:block aspect-square h-64 w-64 rounded-full object-cover'
      />
      <div className='flex flex-col flex-1'>
        <h4 className='flex gap-4 text-mlg font-light text-primary-aqua md:text-lg'>
          <Quote />
          {title}
        </h4>
        <p className='mt-9 font-light'>{textContent}</p>
        <div className='flex gap-4 mt-auto pt-9'>
          <ImageBlock
            asset={{ filename: authorProfileImg?.filename, alt: authorName }}
            width={60}
            height={60}
            className='lg:hidden aspect-square h-16 w-16 rounded-full object-cover'
          />
          <p className='flex flex-col gap-2 font-montserrat'>
            <b className='text-base font-light text-primary-aqua md:text-lg'>{authorName}</b>
            <span className='text-sm uppercase md:text-initial'>{authorPosition}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
