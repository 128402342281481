import { ReactNode } from "react";
import clsx from "clsx/lite";
import styles from '@/components/tooltip/Tooltip.module.scss';

interface ITooltipProps {
  children: ReactNode;
  content: string;
  position?: 'top' | 'bottom' | 'top-right';
}

export function Tooltip(props: ITooltipProps) {
  const {children, content, position = 'top'} = props;
  return (
    <div className={clsx("relative", styles.tooltip)}>
      {children}
      <span className={clsx(
        styles.tooltipContent,
        "absolute bg-primary-black/50 px-3 py-1 rounded-lg opacity-0 pointer-events-none whitespace-nowrap",
        position === 'top' && "bottom-full mb-2 -left-1/2 -right-1/2",
        position === 'bottom' && "top-full mt-2 -left-1/2 -right-1/2",
        position === 'top-right' && "bottom-full right-0 mb-2"
      )}>
        {content}
      </span>
    </div>
  )
}
