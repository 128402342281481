import { ISvgProps } from "@/types/ISvgProps";

export const PauseButton = (props: ISvgProps) => {
  const { color = "currentColor", width = 60, height = 60 } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color}
      className="bi bi-pause-circle-fill" viewBox="0 0 16 16">
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5m3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5" />
    </svg>
  );
};
