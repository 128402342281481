'use client';

import { useState } from 'react';
import { IServiceOverviewProps } from '@/components/service-overview/type';
import { twMerge } from 'tailwind-merge';
import SnapCarousel from '@/components/snap-carousel/SnapCarousel';
import clsx from 'clsx/lite';
import { Arrow } from '@/assets/Arrow';
import ImageBlock from '@/components/image-block/ImageBlock';
import Link from 'next/link';

export default function ServiceOverviewDesktop(props: IServiceOverviewProps) {
  const { categories } = props;
  const [activeCategoryIdx, setActiveCategoryIdx] = useState(0);

  function onClickWrapper(idx: number) {
    return () => setActiveCategoryIdx(idx);
  }

  if (!categories?.length) return null;

  return (
    <div
      data-testid='service-desktop'
      className='hidden grid-cols-2 overflow-hidden rounded-b-3.5xl rounded-tl-3.5xl border border-primary-aqua dark:bg-primary-black md:grid'
    >
      {/* CATEGORY LIST */}
      <div className='px-12 py-4'>
        {categories.map((linkGroup, index) => (
          <button
            type='button'
            onMouseEnter={onClickWrapper(index)}
            key={`${linkGroup?._uid}_${index.toString()}`}
            className={twMerge(
              'w-full border-b border-primary-darkAqua py-12 text-left transition-all duration-500 last:border-b-0',
              'group hover:border-primary-aqua',
              index === activeCategoryIdx && 'border-primary-aqua',
              index === activeCategoryIdx - 1 && 'border-primary-aqua', // Also add colored border to the previous card.
            )}
          >
            <p
              className={twMerge(
                'text-lg transition-all duration-500 group-hover:text-primary-aqua',
                index === activeCategoryIdx && 'text-primary-aqua'
              )}
            >
              {linkGroup?.title}
            </p>
          </button>
        ))}
      </div>

      {/* CURRENTLY SELECTED CATEGORY */}
      <SnapCarousel className='h-full' externalIndex={activeCategoryIdx}>
        {categories.map((category, idx) => (
          <div
            key={`${category._uid}_${idx.toString()}`}
            className={clsx(
              'pointer-events-none relative flex w-full shrink-0 snap-start items-center justify-center bg-cover bg-no-repeat p-12',
              idx === activeCategoryIdx && 'is-active pointer-events-auto'
            )}
          >
            {category.background?.filename && (
              <ImageBlock
                asset={category.background}
                className='absolute inset-0 object-cover h-full w-full'
                width={512}
                height={300}
              />
            )}
            <div className='grid w-full auto-rows-fr gap-3'>
              {category.sublinks?.map((lk) => (
                <Link
                  key={lk._uid}
                  href={lk.link?.url}
                  target={lk.link?.target}
                  className={clsx(
                    'relative flex gap-6 overflow-hidden rounded-xl bg-primary-black/80 p-6 shadow-md',
                    'group/card h-full transition-all duration-500 hover:bg-black hover:text-primary-aqua'
                  )}
                >
                  <p className='flex-1 text-initial uppercase tracking-normal'>{lk.label}</p>
                  <Arrow height={20} width={20} />
                </Link>
              ))}
            </div>
          </div>
        ))}
      </SnapCarousel>
    </div>
  );
}
