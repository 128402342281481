import {IAsset} from "@/types/IAsset";

export enum ELinkTarget {
  Blank = '_blank',
  Self = '_self'
}


export interface ILink {
  url: string;
  target?: ELinkTarget;
}

export interface ISbResolvedLink {
  url: string;
  cached_url: string;
  target?: ELinkTarget;
  linktype: "url" | "story";
  fieldtype: "multilink";
}

export interface IPageLink {
  _uid?: string;
  label: string;
  icon?: IAsset;
  link: ILink;
   
  sublinkBlocks?: ILinkGroup[]
}

export interface IPageLinkRaw extends Omit<IPageLink, 'link'> {
  link: ISbResolvedLink
}

export interface ILinkGroup {
  _uid?: string;
  title?: string;
  description?: string;
  background?: IAsset;
  sublinks: IPageLink[];
}
