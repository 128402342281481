import { ISvgProps } from "@/types/ISvgProps";
import clsx from "clsx/lite";

export interface IArrowProps extends ISvgProps {
  rotate?: boolean
}

export function ArrowWithBorder(props: IArrowProps) {
  const {color = 'currentColor', width = 40, height = 40, rotate} = props;

  return (
    <svg className={clsx("flex-shrink-0", rotate && "rotate-180")} width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="19" stroke={color} strokeWidth="2" />
      <path d="M20 15L26 20L20 25" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 20L26 20" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
