import { IBlog } from '@/types/IBlog';
import Link from 'next/link';
import ImageBlock from '@/components/image-block/ImageBlock';

export default function BlogItem(props: IBlog) {
  const { fullSlug = "", heroImage, tag, title } = props;

  return (
    <Link href={`/${fullSlug}`}>
      <div className='flex h-full flex-col'>
        {heroImage?.filename && (
          <ImageBlock
            asset={heroImage}
            fullWidth
            height={250}
            className='border-primary-aqua h-64 rounded-b-3.5xl rounded-tl-3.5xl border object-cover'
          />
        )}
        {Boolean(tag?.length) && <p className='text-primary-aqua mt-4'>{tag?.map((tg) => tg.text).join(', ')}</p>}
        <div className='mt-3 font-normal'>{title}</div>
      </div>
    </Link>
  );
}
