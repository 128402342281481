import { ISvgProps } from "@/types/ISvgProps";

export const Download = (props: ISvgProps) => {
  const { color = "currentColor", width = 40, height = 40 } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 40 40" className="flex-shrink-0">
      <path d="M28.275 3.96833C34.2633 7.21167 38.3333 13.55 38.3333 20.8333C38.3333 31.4117 29.745 40 19.1667 40C8.58833 40 0 31.4117 0 20.8333C0 13.55 4.07 7.21167 10.0583 3.96833L11.0717 5.31667C5.48333 8.24167 1.66667 14.095 1.66667 20.8333C1.66667 30.4917 9.50833 38.3333 19.1667 38.3333C28.825 38.3333 36.6667 30.4917 36.6667 20.8333C36.6667 14.095 32.85 8.24167 27.2617 5.31667L28.275 3.96833ZM20 0V24.71L26.245 17.0367L27.5 18.1467L19.1667 28.3333L10.8333 18.165L12.0617 17.0333L18.3333 24.7133V0H20Z" />
    </svg>
  );
};