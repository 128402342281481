import { ISvgProps } from "@/types/ISvgProps";
import clsx from "clsx/lite";

export interface IChevronProps extends ISvgProps {
  rotate?: boolean
}

export function Chevron(props: IChevronProps) {
  const {color = 'currentColor', width = 24, height = 24, rotate, className} = props;
  return (
    <svg className={clsx("flex-shrink-0", rotate && "rotate-180", className)} width={width} height={height} viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L9 7L1 13" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
