'use client';

import { EFormType, IFormItemProps } from '@/components/form/types';
import { debounce } from '@/utils/helpers';
import { KeyboardEvent, useRef, useState } from 'react';
import XClose from '@/assets/XClose';
import clsx from "clsx/lite";
import styles from './FormInput.module.scss';

export function FormInput(props: IFormItemProps) {
  const { formName, label, type, regexValidation, required, errorMessage } = props;
  const Input = type === EFormType.Multiline ? 'textarea' : 'input';

  const [ hasError, setHasError ] = useState<boolean>(false);
  const ref = useRef(null);

  function keyUpHandler(event: KeyboardEvent<HTMLElement>) {
    const value: string = (event.target as HTMLInputElement)?.value;
    if (regexValidation && !value?.match(new RegExp(regexValidation))) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }

  function onClearInput() {
    if (ref.current) {
      (ref.current as HTMLInputElement).value = '';
      setHasError(false);
    }
  }

  return (
    <div className='relative'>
      <label htmlFor={ formName } className='flex gap-4 items-start relative'>
        <Input
          id={ formName }
          name={ formName }
          type={ type }
          rows={ 10 }
          required={ required }
          ref={ ref }
          placeholder={ label }
          onKeyUp={ debounce(keyUpHandler, 500) }
          className={ clsx(
            'border border-primary-black rounded-md bg-transparent px-1 py-2 text-base dark:border-primary-white dark:text-primary-white peer placeholder-transparent',
            type === EFormType.Checkbox ? "mt-1.5" : 'w-full'
          ) }
        />
        <span className={ clsx(
          type !== EFormType.Checkbox && 'absolute transition-all duration-500 p-1 -top-4 scale-90',
          type !== EFormType.Checkbox && 'peer-focus:-top-4 peer-focus:scale-90 peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-2',
          type !== EFormType.Checkbox && styles.label
        ) }>
          { [label, required ? ' *' : ''].join('') }
        </span>
      </label>
      { hasError && errorMessage && (
        <button
          type='button'
          aria-label='close'
          className='absolute right-0 top-3'
          onClick={ onClearInput }
        >
          <XClose width={ 24 } height={ 24 }/>
        </button>
      ) }
      <p className='mt-3 min-h-6 text-sm font-bold text-primary-error'>{ hasError && errorMessage }</p>
    </div>
  );
}
