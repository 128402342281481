import { ISvgProps } from "@/types/ISvgProps";

export default function Quote(props: ISvgProps) {
  const {width = 36, height = 28, color = 'currentColor', className} = props;
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5884 14.3648L24.6595 15.7767L25.9671 15.2396C26.71 14.9344 27.5285 14.7648 28.3908 14.7648C31.8468 14.7648 34.6001 17.4816 34.6001 20.7739C34.6001 24.0662 31.8468 26.783 28.3908 26.783C25.0026 26.783 22.4867 24.2226 21.7595 20.7681C20.6329 15.4166 22.5338 10.4343 24.919 6.83228C25.8662 5.40188 26.8749 4.2128 27.7674 3.33071C27.575 3.649 27.3726 3.9923 27.1676 4.35866C25.8384 6.73438 24.3748 10.1194 24.5884 14.3648Z"
        stroke={color} strokeWidth="2"/>
      <path
        d="M4.56254 14.3648L4.63358 15.7767L5.94124 15.2396C6.68416 14.9344 7.50259 14.7648 8.3649 14.7648C11.821 14.7648 14.5742 17.4816 14.5742 20.7739C14.5742 24.0662 11.821 26.783 8.3649 26.783C4.97674 26.783 2.46085 24.2226 1.73361 20.7681C0.606976 15.4166 2.50788 10.4343 4.8931 6.83228C5.8403 5.40188 6.84906 4.2128 7.74152 3.33071C7.5491 3.649 7.34674 3.9923 7.14176 4.35866C5.8125 6.73438 4.34891 10.1194 4.56254 14.3648Z"
        stroke={color} strokeWidth="2"/>
    </svg>

  )
}
