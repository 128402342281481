import { ISvgProps } from "@/types/ISvgProps";

export const PlayButton = (props: ISvgProps) => {
  const { color = "currentColor", width = 60, height = 60 } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color}
      className="bi bi-play-circle-fill" viewBox="0 0 16 16">
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
    </svg>
  );
};
