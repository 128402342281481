'use client';

import { IAsset } from '@/types/IAsset';
import Image from 'next/image';
import { ICMSCompatibleComponent } from '@/types/ICMSCompatibleComponent';
import clsx from 'clsx/lite';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { EObjectFit } from '@/constants/enums';

export interface IImageBlockProps extends Partial<ICMSCompatibleComponent> {
  asset?: IAsset;
  height?: number;
  width?: number;
  style?: CSSProperties;
  /** Enable preloading for images above-the-fold */
  priority?: boolean;
  roundedCorners?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  objectFit?: EObjectFit;
  className?: string;
  fill?: boolean;
}

export default function ImageBlock(props: IImageBlockProps) {
  const {
    asset,
    width,
    height,
    style,
    editable,
    roundedCorners,
    className,
    priority = false,
    fullHeight = false,
    fullWidth = false,
    objectFit,
    fill,
    ...rest
  } = props;

  const [imgSrc, setImgSrc] = useState(asset?.filename);

  useEffect(() => {
    if (asset?.filename) setImgSrc(asset?.filename);
  }, [asset]);

  function onImageNotFoundHandler() {
    setImgSrc('./not-found.svg');
  }

  const imgWidth = useMemo(() => {
    if (!priority && fill) return 1920;
    if (!priority && !fill) return width;
    return width || 1920;
  }, [priority, width, fill]);

  const imgHeight = useMemo(() => {
    if (!priority && fill) return 1440;
    if (!priority && !fill) return height;
    return height || 900;
  }, [priority, height, fill]);

  if (!asset?.filename) return null;

  return (
    <Image
      {...editable}
      src={imgSrc || './not-found.svg'}
      alt={asset?.alt}
      priority={priority}
      fetchPriority={priority ? 'high' : 'auto'}
      width={imgWidth || 500}
      height={imgHeight || 250}
      style={style}
      loading="eager"
      sizes="(max-width: 768px) 100vw, 80vw"
      className={clsx(
        'h-[revert-layer]',
        roundedCorners && 'rounded-b-4xl rounded-tl-4xl border border-primary-aqua',
        objectFit === EObjectFit.Contain && 'object-contain',
        objectFit === EObjectFit.Cover && 'object-cover',
        fullHeight && 'h-full',
        !imgHeight && "h-auto",
        fullWidth && 'w-full',
        !imgWidth && "w-auto",
        fill && 'absolute inset-0 h-full w-full',
        className
      )}
      quality={80}
      onError={onImageNotFoundHandler}
      {...rest}
    />
  );
}
