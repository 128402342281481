import { ELinkTarget, ILink, ISbResolvedLink } from "../types/ILink";

export function isImage(filename: string): boolean {
  return (/\.(gif|jpe?g|tiff?|png|webp|bmp|svg)$/i).test(filename);
}

/**
 * Get the contrasting text color based on the background color
 * TODO Replace black with the brand color
 * */
export function getContrastYIQ(hexcolor: string): "black" | "white" {
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? "black" : "white";
}

/** Check if an object is empty (e.g. contains no properties) */
export const isObjectEmpty = <T>(value: T) => {
  if (!value) return true;
  return Object.keys(value).length === 0;
};

export const transformResolvedLinkToLink = (link: ISbResolvedLink): ILink => {
  // Extract url from storyblok data object
  const originalUrl = link?.cached_url || link?.url || "";

  // Special processing for /home
  const urlWithSpecialPaths = originalUrl === "home" ? "/" : originalUrl;

  // Add slash to the front of the url
  const finalUrl = (urlWithSpecialPaths && link?.linktype === "story") && !urlWithSpecialPaths.startsWith("/") ? `/${urlWithSpecialPaths}` : urlWithSpecialPaths;

  return ({
    url: finalUrl,
    target: link?.target || ELinkTarget.Self
  });
};
export const debounce = <T>(callback: (...params: T[]) => void, timeout = 300) => {
  let timeoutId: number;
  return (...args: T[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};

export function slugify(str: string) {
  return str
    .replaceAll(" ", "-")
    .replaceAll(/[^\w\s-]/g, "")
    .replaceAll(/((?<=[a-z\d])[A-Z]|(?<=[A-Z\d])[A-Z](?=[a-z]))/g, "-$1")
    .toLowerCase();
}

export function shuffleArray(arr: unknown[]) {
  const array = [...arr];
  let i = array.length;
  while (i > 0) {
    i -= 1;
    const ri = Math.floor(Math.random() * i);
    [array[i], array[ri]] = [array[ri], array[i]];
  }
  return array;
}

export function isServer() {
  return typeof window !== "undefined";
}
