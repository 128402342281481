'use client';

import { ICMSCompatibleComponent } from "@/types/ICMSCompatibleComponent";
import { IAsset } from "@/types/IAsset";
import clsx from "clsx/lite";
import { useRef } from "react";
import CustomVideoControls from "@/components/video/CustomVideoControls";

export enum EVideoControls {
  None = "none",
  Default = "default",
  Custom = "custom"
}

export interface IVideoProps extends Partial<ICMSCompatibleComponent> {
  src: IAsset;
  previewImage?: IAsset;
  autoplay?: boolean;
  controls?: EVideoControls;
  loop?: boolean;
  roundedTopLeft?: boolean;
  width?: number;
}

export default function Video(props: IVideoProps) {
  const { src, previewImage, autoplay = false, controls = true, loop = false, roundedTopLeft, width, editable } = props;

  /** Container ref contains the custom controls, and will be the element that request fullscreen */
  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  if (!src?.filename) return null;

  return (
    <div className="relative" ref={containerRef} {...editable}>
      {/* // We can safely ignore this rule as this is not a Web case. See https://www.w3.org/WAI/WCAG21/Understanding/captions-live.html */}
      { }
      <video
        controls={controls === EVideoControls.Default}
        autoPlay={autoplay}
        muted={autoplay}
        loop={loop}
        poster={previewImage?.filename}
        ref={videoRef}
        className={clsx(!width && "w-full", roundedTopLeft && "rounded-tl-4xl")}
        {...(width ? { width } : {})}
      >
        <source src={src.filename} />
        <p>
          Your browser doesn&apos;t support HTML video. Here is a
          <a href={src.filename} download={src.filename}>link to the video</a> instead.
        </p>
      </video>
      {/* Custom video controls */}
      {controls === EVideoControls.Custom && <CustomVideoControls videoRef={videoRef} containerRef={containerRef} />}
    </div>
  );
}
