'use client';

import {ICMSCompatibleComponent} from "@/types/ICMSCompatibleComponent";
import {useEffect, useMemo, useRef, useState} from "react";
import {twMerge} from "tailwind-merge";
import clsx from "clsx/lite";

export interface IWordCloud extends Partial<ICMSCompatibleComponent> {
  text: string;
  color?: string;
  /** Distance from the left edge of the component (in percentage) */
  offsetLeft?: number;
}

export default function WordCloud(props: IWordCloud) {
  const {text, color, offsetLeft = 0, editable} = props;
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const textArray = useMemo(() => {
    /** Get an estimated word count based on the assumptions:
     - Character has a length of 22px
     - Max viewport width is 7680px (8K screen); */
    function getLoopCount(): number {
      const charPerWord = text.length + 1; // Account for space
      const numberOfCharPerLine = 7680 / 22;
      return Math.round(numberOfCharPerLine / charPerWord);
    }

    const arr = [];
    for (let i = 0; i < getLoopCount(); i += 1) {
      arr.push(text);
    }
    return arr;
  }, [text]);

  useEffect(() => {
    if (!ref.current) return;

    function intersectionCallback(entries: IntersectionObserverEntry[]) {
      const isIntersecting = entries.every(entry => entry.isIntersecting);
      setIsVisible(isIntersecting);
    }

    const observer = new IntersectionObserver(intersectionCallback, {threshold: 0.5});
    observer.observe(ref.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <div ref={ref} className="whitespace-nowrap overflow-hidden" {...editable}>
      <div
        data-testid='word-cloud-slider'
        style={{left: `${offsetLeft || 0}%`}}
        className={twMerge(
          "py-2 relative flex text-primary-gray transform -translate-x-1/2 transition-all duration-1000",
          isVisible && 'translate-x-0'
        )}
      >
        <div className="absolute right-full">
          {textArray?.map((txt, i) => (
            <span key={txt + i.toString()}
              className={`text-${color || 'primary-white'} text-m2xl md:text-2xl mr-2.5 font-light opacity-50 font-montserrat`}>
              {txt}
            </span>
          ))}
        </div>
        {textArray?.map((txt, i) => (
          <span key={txt + i.toString()} className={clsx(
            'text-m2xl md:text-2xl mr-2.5 font-light font-montserrat',
            `text-${color || 'primary-white'}`,
            i === 0 ? 'opacity-100 animation-delay-1000 font-normal' : 'opacity-50',
            isVisible && 'animate-pop'
          )}>
            {txt}
          </span>
        ))}
      </div>
    </div>
  )
}
