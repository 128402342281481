'use client';

import { ICMSCompatibleComponent } from '@/types/ICMSCompatibleComponent';
import DatabaseSlash from '@/assets/DatabaseSlash';
import Button, { EButtonVariants } from '@/components/button/Button';
import useEmblaCarousel from 'embla-carousel-react';
import usePagination from "@/hooks/usePagination";
import { twMerge } from "tailwind-merge";
import { ITestimonials } from "@/types/ITestimonials";
import TestimonialItem from "@/components/testimonials-carousel/TestimonialItem";

export interface ITestimonialsCarouselProps extends Partial<ICMSCompatibleComponent> {
  list?: ITestimonials[];
  limit?: number;
}

export default function TestimonialsCarousel(props: ITestimonialsCarouselProps) {
  const { list, editable } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: "auto",
    loop: true
  });

  function onPrevButtonClick() {
    emblaApi?.scrollPrev();
  }

  function onNextButtonClick() {
    emblaApi?.scrollNext();
  }

  const { paginationList, selectedIndex } = usePagination(emblaApi);

  if (!list?.length) return null;

  return (
    <div {...editable} ref={emblaRef} className='relative overflow-hidden'>
      <div data-testid='testimonials-carousel' className='flex will-change-transform'>
        {list?.map((item, i) => (
          <div
            className='flex-1-per-page min-w-0 *:h-full cursor-grab active:cursor-grabbing'
            key={`${i.toString()}`}
          >
            <TestimonialItem {...item} />
          </div>
        ))}
      </div>
      <div className='mt-8 flex items-center justify-between'>
        <Button
          variant={EButtonVariants.SecondaryLinkFront}
          onClick={onPrevButtonClick}
          buttonType='button'
          className="disabled:cursor-not-allowed"
          disabled={!emblaApi?.canScrollPrev()}
        >
          BACK
        </Button>
        {paginationList.length > 1 && (
          <div data-testid='carousel-pagination' className='hidden sm:flex w-full justify-center gap-3'>
            { paginationList.map((_, i) => (
              <div
                className={ twMerge('h-3 w-3 rounded-full border-2 border-primary-white transition-all duration-500', i === selectedIndex && 'bg-primary-white') }
                key={ `${ i.toString() }` }
              />
            )) }
          </div>
        ) }
        <Button
          variant={EButtonVariants.SecondaryLink}
          onClick={onNextButtonClick}
          buttonType='button'
          className="disabled:cursor-not-allowed"
          disabled={!emblaApi?.canScrollNext()}
        >
          NEXT
        </Button>
      </div>
      {!list?.length && (
        <p className='col-span-3 my-6 flex h-40 flex-col items-center text-center'>
          <DatabaseSlash width={60} height={60} />
          <span className='mt-6'>No data to display.</span>
        </p>
      )}
    </div>
  );
}
