import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import {Chevron} from "@/assets/Chevron";
import styles from './SnapCarousel.module.scss';

export interface ISnapCarouselProps {
  children: ReactElement[];
  on?: {
    nextSlide?: (nextIndex: number) => void;
    prevSlide?: (prevIndex: number) => void;
  },
  className?: string;
  wrapperClassName?: string;
  externalIndex?: number;
  showNav?: boolean;
}

export default function SnapCarousel(props: ISnapCarouselProps) {
  const {className, wrapperClassName, children, on, externalIndex, showNav} = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const carouselRef = useRef<HTMLDivElement>(null);
  const [carouselWidth, setCarouselWidth] = useState(0);
  useEffect(() => {
    if (carouselRef.current) setCarouselWidth(carouselRef.current.scrollWidth);
  }, [carouselRef])

  useEffect(() => {
    const resizeHandler = () => {
      if (carouselRef.current) setCarouselWidth(carouselRef.current.scrollWidth);
    }

    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [])

  useEffect(() => {
    const preventDefault = (e: WheelEvent) => {
      if (Math.abs(e.deltaY) < Math.abs(e.deltaX)) {
        e.preventDefault();
      }
    }

    const _carousel = carouselRef.current;
    _carousel?.addEventListener('wheel', preventDefault, {passive: false});
    return () => _carousel?.removeEventListener('wheel', preventDefault);
  }, [])

  const scrollAmount = useMemo(() => carouselWidth / (children.length || 1), [carouselWidth, children])

  function onClickNextSlide() {
    const nextSlideIndex = activeIndex === (children.length - 1) ? 0 : activeIndex + 1;
    if (nextSlideIndex !== 0) {
      carouselRef.current?.scrollBy?.({left: scrollAmount, behavior: 'smooth'});
    } else {
      carouselRef.current?.scrollTo?.({left: 0, behavior: 'smooth'});
    }

    setActiveIndex(nextSlideIndex);
    on?.nextSlide?.(nextSlideIndex);
  }

  function onClickPrevSlide() {
    const prevSlideIndex = activeIndex === 0 ? (children.length - 1) : activeIndex - 1;
    if (prevSlideIndex !== children.length - 1) {
      carouselRef.current?.scrollBy?.({left: scrollAmount * -1, behavior: 'smooth'});
    } else {
      carouselRef.current?.scrollTo?.({left: scrollAmount * children.length, behavior: 'smooth'});
    }

    setActiveIndex(prevSlideIndex);
    on?.prevSlide?.(prevSlideIndex);
  }

  useEffect(() => {
    if (typeof externalIndex === 'undefined') return;
    if (externalIndex === activeIndex) return;

    const newPosition = externalIndex * scrollAmount;
    carouselRef?.current?.scrollTo({ left: newPosition, behavior: 'smooth' });
    setActiveIndex(externalIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalIndex, scrollAmount]);

  return (
    <div className={wrapperClassName}>
      <div
        className={clsx(
          'relative flex w-full touch-none snap-x snap-mandatory overflow-x-auto will-change-scroll',
          styles.snapCarousel,
          className
        )}
        ref={carouselRef}
      >
        {children}
      </div>
      {showNav && (
        <>
          <button
            className={clsx(
              'absolute left-2 top-1/2 z-10 -translate-y-1/2 cursor-pointer rounded-full p-4 transition-all hover:bg-primary-white/50',
              'disabled:cursor-not-allowed disabled:bg-primary-white/50'
            )}
            data-testid='prev-slide'
            aria-label='Previous slide'
            type='button'
            onClick={onClickPrevSlide}
          >
            <Chevron rotate />
          </button>
          <button
            className={clsx(
              'absolute right-2 top-1/2 z-10 -translate-y-1/2 cursor-pointer rounded-full p-4 transition-all hover:bg-primary-white/50',
              'disabled:cursor-not-allowed disabled:bg-primary-white/50'
            )}
            data-testid='next-slide'
            aria-label='Next slide'
            type='button'
            onClick={onClickNextSlide}
          >
            <Chevron />
          </button>
        </>
      )}
    </div>
  );
}
