export enum EBreakpoints {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  "2XL" = 1536
}

export enum EContainerGap {
  None = "gap--none",
  Small = "gap--small",
  Medium = "gap--medium",
  Large = "gap--large",
  XLarge = "gap--x-large"
}

export enum EMargin {
  None = "margin--none",
  Small = "margin--small",
  Medium = "margin--medium",
  Large = "margin--large",
  XLarge = "margin--x-large"
}

export enum EPadding {
  None = "padding--none",
  Small = "padding--small",
  Medium = "padding--medium",
  Large = "padding--large",
  XLarge = "padding--x-large"
}

export enum EGridColumnLayout {
  "1 Column (Reverse)" = 'grid-cols-1-reverse',
  "1 Column" = "grid-cols-1-col",
  "2 Columns (50% - 50%)" = "grid-cols-2-cols--50-50",
  "2 Columns (66% - 33%)" = "grid-cols-2-cols--66-33",
  "2 Columns (75% - 25%)" = "grid-cols-2-cols--75-25",
  "2 Columns (33% - 66%)" = "grid-cols-2-cols--33-66",
  "2 Columns (25% - 75%)" = "grid-cols-2-cols--25-75",
  "3 Columns (33% - 33% - 33%)" = "grid-cols-3-cols--33-33-33",
  "3 Columns (50% - 25% - 25%)" = "grid-cols-3-cols--50-25-25",
  "3 Columns (25% - 50% - 25%)" = "grid-cols-3-cols--25-50-25",
  "3 Columns (25% - 25% - 50%)" = "grid-cols-3-cols--25-25-50",
  "4 Columns" = "grid-cols-4-cols"
}

export enum EFlexDirection {
  Column = "flex-col",
  Row = "flex-row",
  ColumnReversed = "flex-col-reverse",
  RowReversed = "flex-row-reverse",
}

export enum EJustifyContent {
  Center = "justify-center",
  Between = "justify-between",
  Around = "justify-around",
  Start = "justify-start",
  End = "justify-end",
}

export enum EAlignItems {
  Center = "items-center",
  Start = "items-start",
  End = "items-end",
}

export enum EColorScheme {
  Dark = "dark",
  Light = "light",
}

export enum ELetterSpacing {
  Tight = "letter-spacing--tight",
  Normal = "letter-spacing--normal",
  Wide = "letter-spacing--wide"
}

export enum ETextAlign {
  Left = "text-left",
  Center = "text-center",
  Right = "text-right"
}

export enum ESpacing {
  Small = 'spacing--small',
  Medium = 'spacing--medium',
  Large = 'spacing--large',
  XLarge = 'spacing--x-large',
}

export enum EFontSize {
  Small = 'font-size--small',
  Medium = 'font-size--medium',
  Large = 'font-size--large',
  XLarge = 'font-size--x-large',
  XXLarge = 'font-size--xx-large',
  XXXLarge = 'font-size--xxx-large',
}

export enum EObjectFit {
  Contain = 'contain',
  Cover = 'cover'
}

export enum EBgElementPosition {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
  'Top Left' = 'top-left',
  'Top Right' = 'top-right',
  'Bottom Right' = 'bottom-right',
  'Bottom Left' = 'bottom-left',
}

export enum EBackgroundTransformation {
  Gradient = 'gradient',
  HalfRight = 'half-right',
}
