'use client';

import Image from "next/image";
import Link from "next/link";
import { isObjectEmpty } from "@/utils/helpers";
import { HamburgerMenu } from "@/assets/HamburgerMenu";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx/lite";
import XClose from "@/assets/XClose";
import { IHeaderProps } from "@/types/IHeaderProps";
import { ICMSCompatibleComponent } from "@/types/ICMSCompatibleComponent";
import HeaderLink from "@/components/header/HeaderLink";
import HeaderSubmenu from "@/components/header/HeaderSubmenu";
import styles from "./Header.module.scss";
import { useScrollDirection } from "@/hooks/useScrollDirection";

export default function Header(props: IHeaderProps & Partial<ICMSCompatibleComponent>) {
  const { editable, logo, links } = props;

  const [ menuOpen, setMenuOpen ] = useState(false);
  const [ expandedMenuId, setExpandedMenuId ] = useState<string>('');

  const headerRef = useRef<HTMLHeadElement>(null);

  function toggleMobileMenu() {
    setMenuOpen( !menuOpen);
  }

  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth > 768) {
        setMenuOpen(false);
      }

      setTimeout(() => {
        document.body.style.setProperty("--header-height", `${ headerRef.current?.clientHeight || 96 }px`);
      }, 100);
    }

    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  const { scrollDir, lastScrollY } = useScrollDirection();

  const scrollYThreshold = 96;

  return (
    <div className='relative h-0 w-full lg:sticky lg:top-0 z-50'>
      <header
        { ...editable }
        className={ clsx(
          'relative top-0 overflow-hidden p-6 text-primary-white lg:sticky lg:overflow-visible lg:px-10 lg:py-7 xl:px-20 transition-all duration-500',
          menuOpen && 'max-lg:h-svh',
          (scrollDir === 'up' || lastScrollY < scrollYThreshold) && 'translate-y-0',
          (scrollDir === 'down' && lastScrollY >= scrollYThreshold && !expandedMenuId) && '-translate-y-full',
          (expandedMenuId || lastScrollY >= scrollYThreshold) && 'bg-black',
          styles.header
        ) }
        ref={ headerRef }
      >
        <div className='content-boundary flex items-center'>
          {/* ==== LOGO ==== */ }
          { logo && (
            <Link href='/' className='flex-1'>
              <Image
                src={ logo?.filename }
                alt={ logo?.alt }
                height={ 40 }
                width={ 175 }
                className='h-5 md:h-10 w-auto object-contain'
              />
            </Link>
          ) }

          {/* ==== HAMBURGER MENU (ON MOBILE) ==== */ }
          <div className='ml-9 flex justify-end lg:hidden'>
            <button data-testid='hamburger-menu' onClick={ toggleMobileMenu } type='button' aria-label='Menu'>
              <HamburgerMenu/>
            </button>
          </div>

          {/* ==== LINK ARRAY ==== */ }
          <nav
            data-testid='menu'
            className={ clsx(
              'opacity-0 transition-opacity duration-500 lg:flex-1 lg:opacity-100',
              menuOpen && 'fixed inset-0 z-50 bg-black/20 opacity-100 backdrop-blur-sm',
              menuOpen && 'header-menu--open' // class used to disable scrolling on the html
            ) }
          >
            <div
              className={ clsx(
                'm-0 flex w-80 flex-col justify-start gap-6 px-6 py-16 max-lg:overflow-y-auto lg:w-auto lg:flex-row lg:justify-end lg:gap-10 lg:p-0',
                'group absolute bottom-0 right-0 top-0 transition-all duration-500 lg:relative',
                menuOpen
                  ? 'opacity-1 bg-primary-blueDarkNight visible translate-x-0 shadow-xl'
                  : 'collapse translate-x-full opacity-0 lg:visible lg:translate-x-0 lg:opacity-100'
              ) }
            >
              { links
                ?.filter((lk) => !isObjectEmpty(lk.link))
                .map((link) => (
                  <HeaderLink
                    { ...link }
                    key={ link.label }
                    expandedMenuId={ expandedMenuId }
                    setExpandedMenuId={ setExpandedMenuId }
                  />
                )) }
            </div>

            {/* SUBMENU (DESKTOP) */ }
            <HeaderSubmenu expandingMenu={ links?.find((lk) => lk._uid === expandedMenuId) }/>

            {/* ==== CLOSE BUTTON (ON MOBILE) ==== */ }
            { menuOpen && (
              <button
                type='button'
                aria-label='close'
                onClick={ toggleMobileMenu }
                className='absolute right-5 top-5 text-primary-white'
              >
                <XClose width={ 32 } height={ 32 }/>
              </button>
            ) }
          </nav>
        </div>
      </header>
    </div>
  );
}
