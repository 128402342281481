'use client';

import { ILink, IPageLink } from '@/types/ILink';
import Link from 'next/link';
import { ChevronDown } from '@/assets/ChevronDown';
import clsx from 'clsx/lite';
import { ReactNode } from 'react';

interface ILinkTagProps {
  children?: ReactNode;
  className?: string;
  link: ILink;
}

const LinkTag = (props: ILinkTagProps) => {
  const { children, className, link } = props;

  if (!link?.url) {
    return <div className={className}>{children}</div>;
  }

  return (
    <Link href={link.url} target={link.target} className={className}>
      {children}
    </Link>
  );
};

interface IHeaderLinkProps extends IPageLink {
  expandedMenuId: string;
  setExpandedMenuId: (id: string) => void;
}

export default function HeaderLink(props: IHeaderLinkProps) {
  const { _uid, link, label, sublinkBlocks, expandedMenuId, setExpandedMenuId } = props;

  const hasSublinks = sublinkBlocks && sublinkBlocks.length > 0;

  const isExpanded = expandedMenuId === _uid;

  function toggleSubMenu() {
    setExpandedMenuId((isExpanded ? '' : _uid) || '');
  }

  return (
    <div className='mt-0 grid list-none grid-cols-header-button items-center p-0'>
      <LinkTag link={link} className='hover:text-primary text-base font-semibold group-[.opacity-0]:invisible lg:group-[.opacity-0]:visible'>
        {label}
      </LinkTag>
      {hasSublinks && (
        <button className='span-2' type='button' aria-label='Expand menu item' onClick={toggleSubMenu}>
          <ChevronDown
            width={18}
            height={18}
            className={clsx('ml-5 transition-all duration-500', isExpanded && '-rotate-180')}
          />
        </button>
      )}
      <div
        className={clsx(
          'col-span-2 max-h-0 overflow-y-hidden transition-all duration-500 lg:hidden',
          isExpanded && 'max-h-screen overflow-y-auto'
        )}
      >
        {sublinkBlocks?.map((block, i) => (
          <div key={block._uid || i}>
            {block.sublinks?.map((lk, idx) => (
              <Link href={lk?.link?.url || ''} target={lk.link?.target} key={lk._uid || idx} className='mt-3 block pl-2'>
                {lk.label}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
