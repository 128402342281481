'use client';

import {
  createContext,
  CSSProperties,
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
} from 'react';
import clsx from 'clsx/lite';
import Minus from '@/assets/Minus';
import { twMerge } from 'tailwind-merge';
import classes from './index.module.scss';

export interface IAccordionProps {
  children?: ReactNode;
  className?: string;
}

const AccordionContext = createContext<{ expanded?: boolean; toggle?: (v?: boolean) => void }>({});

export function Accordion(props: IAccordionProps & { initiallyOpen?: boolean }) {
  const { className, children, initiallyOpen = false } = props;

  const [expanded, setExpanded] = useState(initiallyOpen);
  const toggle = (value?: boolean) => setExpanded(value !== undefined ? value : !expanded);

  return (
    <details open className={clsx(classes.accordion, 'group relative', className, expanded && 'expanded')}>
      <AccordionContext.Provider value={{ expanded, toggle }}>{children}</AccordionContext.Provider>
    </details>
  );
}

export function AccordionHeader(props: IAccordionProps & { id: string }) {
  const { className, children, id } = props;

  const { expanded, toggle } = useContext(AccordionContext);

  function onToggle(event: ChangeEvent<HTMLInputElement>) {
    toggle?.(event.target.checked);
  }

  return (
    <>
      <input
        type='checkbox'
        id={id}
        name={id}
        className={clsx('opacity-0 absolute inset-0 w-full h-full', classes.controlInput)}
        onChange={onToggle}
        checked={expanded}
      />
      <summary className={clsx(classes.header, 'flex list-none gap-3 px-5 py-8', className)}>
        <div className='relative'>
          <Minus className={clsx('transition-all duration-500', expanded && 'text-primary-aqua')} />
          <Minus
            className={twMerge(
              'absolute inset-0 rotate-90 opacity-100 transition-all duration-500',
              expanded && 'rotate-180 opacity-0'
            )}
          />
        </div>
        <label htmlFor={id}>{children}</label>
      </summary>
    </>
  );
}

export function AccordionBody(props: IAccordionProps) {
  const { className, children } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);
  useEffect(() => {
    const calculateHeight = () => setHeight(ref.current?.scrollHeight || 0);
    calculateHeight();

    window.addEventListener('resize', calculateHeight);
    return () => window.removeEventListener('resize', calculateHeight);
  }, []);

  return (
    <div
      style={{ '--height': `${height}px` } as CSSProperties}
      className={clsx(classes.body, 'overflow-hidden transition-all duration-500', className)}
      ref={ref}
    >
      {children}
    </div>
  );
}
