import { EmblaCarouselType } from 'embla-carousel';
import { useCallback, useEffect, useState } from 'react';

export default function usePagination(emblaApi?: EmblaCarouselType) {
  const [paginationList, setPaginationList] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelect = useCallback((_api: EmblaCarouselType) => {
    if (!_api) return;
    setSelectedIndex(_api?.selectedScrollSnap());
  }, []);

  const onInit = useCallback((_api: EmblaCarouselType) => {
    if (!_api) return;

    setPaginationList(_api.scrollSnapList());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi
      .on('reInit', onInit)
      .on('reInit', onSelect)
      .on('select', onSelect);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi]);

  return { paginationList, selectedIndex };
}
