import { useEffect, useState } from 'react';

export const useScrollDirection = (): {scrollDir: 'up' | 'down', lastScrollY: number} => {
  const [ scrollDir, setScrollDir ] = useState<'up' | 'down'>('down');
  const [ lastScrollY, setLastScrollY ] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      setScrollDir('down');
    } else if (currentScrollY < lastScrollY) {
      setScrollDir('up');
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lastScrollY ]);

  return { scrollDir, lastScrollY };
};
