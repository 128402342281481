import { ICMSCompatibleComponent } from "@/types/ICMSCompatibleComponent";

export enum EFormType {
  Text = 'text',
  Multiline = 'multiline',
  Email = 'email',
  Password = 'password',
  Checkbox = 'checkbox',
}

export interface IFormItemProps extends Partial<ICMSCompatibleComponent> {
  label: string;
  type: EFormType;
  formName: string;
  errorMessage: string;
  regexValidation: string;
  required?: boolean;
}
