'use client';

import { IPageLink } from "@/types/ILink";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

interface IHeaderSubmenuProps {
  expandingMenu?: IPageLink;
}

export default function HeaderSubmenu(props: IHeaderSubmenuProps) {
  const { expandingMenu } = props;
  const sublinkBlocks = expandingMenu?.sublinkBlocks || [];

  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current) return;
    const height = contentRef.current?.scrollHeight;
    setContentHeight(height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef.current, expandingMenu]);

  return (
    <div
      className={twMerge(
        "absolute top-full left-0 right-0 bg-black z-50 overflow-hidden transition-all duration-500 border-primary-darkAqua border-t-0",
        expandingMenu && "border-t"
      )}
      style={{ height: expandingMenu ? contentHeight : 0 }}
    >
      <div className="content-boundary grid grid-cols-4 gap-x-9 p-6 grid-rows-card" ref={contentRef}>
        {sublinkBlocks.map((block, idx) => (
          <div className="grid grid-rows-subgrid row-span-2" key={block._uid || idx}>
            <p className="text-primary-aqua text-initial pb-8 border-b border-primary-darkAqua">
              {block.title}
            </p>
            <div>
              {block.sublinks.map((lk, i) => (
                <Link href={lk.link.url || ""} target={lk.link.target} key={lk._uid || i} className="block mt-6">
                  {lk.label}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
