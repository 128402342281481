import { ISvgProps } from "@/types/ISvgProps";

export default function Minus(props: ISvgProps) {
  const { width = 24, height = 24 , color = 'currentColor', className} = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.14453 12L18.8588 12" stroke={color} strokeWidth="2" strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>

  )
}
