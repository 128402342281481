import(/* webpackMode: "eager", webpackExports: ["useStoryblokState"] */ "/vercel/path1/node_modules/@storyblok/react/dist/client.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/node_modules/@storyblok/react/dist/live-editing.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionHeader","AccordionBody"] */ "/vercel/path1/src/components/accordion/Accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/components/blog-carousel/BlogCarouselClient.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/button/Button.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/components/carousel/CarouselClient.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/footer/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/container-grid/ContainerGrid.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["FormInput"] */ "/vercel/path1/src/components/form/FormInput/FormInput.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/components/header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/image-block/ImageBlock.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/section/Section.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/components/service-overview/ServiceOverviewDesktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/components/testimonials-carousel/TestimonialsCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/video-background/BackgroundMedia.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/text/Text.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/components/video/Video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/components/word-cloud/WordCloud.tsx");
