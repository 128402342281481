import Link from "next/link";
import { ILink } from "@/types/ILink";
import { ICMSCompatibleComponent } from "@/types/ICMSCompatibleComponent";
import clsx from "clsx/lite";
import { ArrowWithBorder } from "@/assets/ArrowWithBorder";
import { EColorScheme } from "@/constants/enums";
import { MouseEventHandler, ReactNode } from "react";
import { Download } from "@/assets/Download";
import styles from "@/components/button/Button.module.scss";
import { Arrow } from "@/assets/Arrow";

export enum EButtonVariants {
  PrimaryLink = "primary-link",
  SecondaryLink = "secondary-link",
  SecondaryLinkFront = "secondary-link-front",
  DownloadLink = "download-link",
  PrimaryButton = "primary-button",
  SecondaryButton = "secondary-button",
}

export interface IButtonProps extends Partial<ICMSCompatibleComponent> {
  variant: EButtonVariants,
  children?: ReactNode,
  colorScheme?: EColorScheme,
  link?: ILink,
  id?: string,
  disabled?: boolean,
  className?: string,
  rotate?: boolean,
  onClick?: MouseEventHandler<HTMLButtonElement>,
  buttonType?: "button" | "link" | "submit",
}

export default function Button(props: IButtonProps) {
  const {
    variant,
    id,
    link,
    children,
    className,
    editable,
    colorScheme,
    disabled = false,
    rotate = false,
    onClick,
    buttonType = 'link',
  } = props;

  const is = (v: EButtonVariants) => variant === v;
  const btnClassNames = clsx(
    colorScheme || EColorScheme.Light,
    styles.button,
    "tracking-wide inline-flex items-center text-start transition-colors duration-default [&_svg]:transition-none",
    !colorScheme && 'dark:text-primary-white',
    is(EButtonVariants.PrimaryLink) && "text-lg gap-x-4 hover:text-primary focus:text-primary-blueLightDawn",
    is(EButtonVariants.DownloadLink) && "font-semibold text-base leading-6 gap-x-4 hover:text-primary focus:text-primary-aqua",
    (is(EButtonVariants.SecondaryLink) || is(EButtonVariants.SecondaryLinkFront)) && clsx("text-base gap-x-2", !colorScheme && 'text-primary dark:text-primary hover:text-primary-aqua focus:text-primary-aqua/80 dark:focus:text-primary-white'),
    is(EButtonVariants.PrimaryButton) && "text-base rounded-button-default font-semibold py-2 px-10 border-2 border-primary hover:bg-primary-blueLightDawn hover:text-primary-white focus:text-primary-white focus:bg-primary-blueLightDawn focus:border-primary-blueLightDawn",
    is(EButtonVariants.SecondaryButton) && clsx("text-lg rounded-button-default font-semibold", !colorScheme && "text-primary hover:text-primary-blueLightDawn focus:text-primary-black dark:text-primary dark:focus:text-primary-white"),
    disabled && `${styles.disabled} text-primary-gray pointer-events-none cursor-not-allowed border-primary-gray`,
    className
  );

  const renderedChild = (
    <>
      {variant === EButtonVariants.PrimaryLink && <ArrowWithBorder rotate={rotate} />}
      {variant === EButtonVariants.DownloadLink && <Download />}
      {variant === EButtonVariants.SecondaryLinkFront && <Arrow className='rotate-180' />}
      {children}
      {variant === EButtonVariants.SecondaryLink && <Arrow />}
    </>
  );

  if (buttonType !== 'link') {
    return (
      <button
        id={id}
        type={buttonType === "button" ? "button" : "submit"}
        {...onClick && {onClick}}
        disabled={disabled}
        aria-disabled={disabled}
        className={btnClassNames}
      >
        {renderedChild}
      </button>
    );
  }

  return (
    <Link
      id={id}
      href={disabled ? "/#" : (link?.url || "/#")}
      target={link?.target || "_self"}
      download={variant === EButtonVariants.DownloadLink && link?.url?.substring(link?.url?.lastIndexOf("/"))}
      {...editable}
      aria-disabled={disabled}
      className={btnClassNames}
    >
      {renderedChild}
    </Link>
  );
}
