import { ISvgProps } from "@/types/ISvgProps";

export function ChevronDown(props: ISvgProps) {
  const {color = 'currentColor', width = 24, height = 24, className} = props;
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L10 11" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 11L19 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}
