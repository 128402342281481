import { ISvgProps } from "@/types/ISvgProps";

export function HamburgerMenu(props: ISvgProps) {
  const {color = 'currentColor', width = 24, height = 24} = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.06 16H3.94C3.42085 16 3 16.4209 3 16.94V17.06C3 17.5791 3.42085 18 3.94 18H20.06C20.5791 18 21 17.5791 21 17.06V16.94C21 16.4209 20.5791 16 20.06 16Z"
        fill={color} />
      <path
        d="M20.06 6H3.94C3.42085 6 3 6.42085 3 6.94V7.06C3 7.57915 3.42085 8 3.94 8H20.06C20.5791 8 21 7.57915 21 7.06V6.94C21 6.42085 20.5791 6 20.06 6Z"
        fill={color} />
      <path
        d="M20.06 11H3.94C3.42085 11 3 11.4209 3 11.94V12.06C3 12.5791 3.42085 13 3.94 13H20.06C20.5791 13 21 12.5791 21 12.06V11.94C21 11.4209 20.5791 11 20.06 11Z"
        fill={color} />
    </svg>

  )
}
