import { ISvgProps } from "@/types/ISvgProps";

export function Arrow(props: ISvgProps) {
  const {color = 'currentColor', width = 20, height = 20, className} = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 6L14 10L10 14" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 10L14 10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}
