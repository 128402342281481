'use client';

import { ICMSCompatibleComponent } from '@/types/ICMSCompatibleComponent';
import { ReactNode } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import usePagination from '@/hooks/usePagination';
import { twMerge } from 'tailwind-merge';

export interface ICarouselClientProps extends Partial<ICMSCompatibleComponent> {
  list: ReactNode[];
}

export default function CarouselClient(props: ICarouselClientProps) {
  const { list, editable } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const { paginationList, selectedIndex } = usePagination(emblaApi);

  if (!list?.length) return <div>No data to display</div>;

  return (
    <div ref={emblaRef} className='relative overflow-hidden' {...editable}>
      <div className='flex will-change-transform' data-testid='carousel'>
        {list.map((item, i) => (
          <div className='min-w-0 flex-1-per-page *:h-full cursor-grab active:cursor-grabbing select-none' key={`${i.toString()}`}>
            {item}
          </div>
        ))}
      </div>
      {paginationList.length > 1 && (
        <div data-testid='carousel-pagination' className='absolute bottom-10 hidden sm:flex w-full justify-center gap-6'>
          { paginationList.map((_, i) => (
            <div
              className={ twMerge('h-1 w-40 bg-primary-black transition-all duration-500', i === selectedIndex && 'bg-primary-aqua') }
              key={ `${ i.toString() }` }
            />
          )) }
        </div>
      ) }
    </div>
  );
}
