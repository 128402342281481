'use client';

import { PlayButton } from "@/assets/PlayButton";
import { RefObject, useEffect, useState } from "react";
import { PauseButton } from "@/assets/PauseButton";
import { MuteButton } from "@/assets/MuteButton";
import { FullscreenButton } from "@/assets/FullscreenButton";
import { UnMuteButton } from "@/assets/UnMuteButton";
import { FullscreenExitButton } from "@/assets/FullscreenExitButton";
import { debounce } from "@/utils/helpers";
import clsx from "clsx/lite";
import { Tooltip } from "@/components/tooltip/Tooltip";


export interface ICustomVideoControlsProps {
  videoRef: RefObject<HTMLVideoElement | null>;
  containerRef: RefObject<HTMLElement | null>;
}

export default function CustomVideoControls(props: ICustomVideoControlsProps) {
  const { videoRef, containerRef } = props;

  /** All of these states are reflected from the native video states */
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  /** Hide the mouse if no movements is detected in 2 seconds */
  const [shouldHideOverlay, setShouldHideOverlay] = useState<boolean>(true);
  const [timeoutId, setTimeoutId] = useState<number>();

  useEffect(() => {
    if (!videoRef?.current || !containerRef?.current) return;
    const video = videoRef.current;
    const container = containerRef.current;

    if (video.muted) setIsMuted(true);
    if (video.autoplay) setIsPlaying(true);

    // Native video event listeners
    video.addEventListener("ended", () => setIsPlaying(false));
    video.addEventListener("pause", () => setIsPlaying(false));
    video.addEventListener('play', () => setIsPlaying(true));
    video.addEventListener('volumechange', () => setIsMuted(video.muted));

    // Full screen event listeners
    container?.addEventListener("fullscreenchange", () => {
      // Handle the case when user use the Esc key to exit full screen
      if (!document.fullscreenElement) {
        setIsFullscreen(false);
      } else {
        setIsFullscreen(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (!videoRef?.current || !containerRef?.current) return null;

  function handlePlayButton() {
    const video = videoRef.current!;
    if (video.paused || video.ended) {
      video.play();
    } else {
      video.pause();
    }
  }

  function handleMuteButton() {
    const video = videoRef.current!;
    video.muted = !video.muted;
  }

  function handleFullScreenButton() {
    if (document.fullscreenElement) {
      // The document is in fullscreen mode
      document.exitFullscreen();
    } else {
      // The document is not in fullscreen mode
      containerRef.current?.requestFullscreen();
    }
  }

  function handleMouseMove() {
    // Clear all timeouts
    setShouldHideOverlay(false);
    clearTimeout(timeoutId);
    setTimeoutId(undefined);

    // Set new timeout to remove the overlay in 1 second
    const id = window.setTimeout(() => setShouldHideOverlay(true), 1000);
    setTimeoutId(id);
  }

  return (
    <div
      onMouseMove={debounce(handleMouseMove, 20)}
      className={clsx(
        "absolute inset-0 bg-black/50 flex justify-center items-center transition-all duration-default",
        shouldHideOverlay && "opacity-0"
      )}>
      <button type="button" aria-label={isPlaying ? "Pause" : "Play"} className="text-primary-gray"
        onClick={handlePlayButton}>
        <Tooltip content={isPlaying ? "Pause" : "Play"} position="bottom">
          {!isPlaying ? <PlayButton /> : <PauseButton />}
        </Tooltip>
      </button>
      {/* Bottom control group */}
      <div className="absolute bottom-4 right-4 text-primary-gray flex gap-2">
        <button type="button" onClick={handleMuteButton} aria-label={isMuted ? "Mute" : "Unmute"}>
          <Tooltip content={isMuted ? "Mute" : "Unmute"} position="top-right">
            {isMuted ? <UnMuteButton /> : <MuteButton />}
          </Tooltip>
        </button>
        {document?.fullscreenEnabled && (
          <button type="button" aria-label="Toggle fullscreen" onClick={handleFullScreenButton}>
            <Tooltip content={isFullscreen ? "Exit fullscreen" : "Toggle fullscreen"} position="top-right">
              {isFullscreen ? <FullscreenExitButton width={16} height={16} /> :
                <FullscreenButton width={16} height={16} />}
            </Tooltip>
          </button>
        )}
      </div>
    </div>
  );
}
