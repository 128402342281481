'use client';

import { ICMSCompatibleComponent } from '@/types/ICMSCompatibleComponent';
import DatabaseSlash from '@/assets/DatabaseSlash';
import { IBlog } from '@/types/IBlog';
import BlogItem from '@/components/blog-item/BlogItem';
import Button, { EButtonVariants } from '@/components/button/Button';
import useEmblaCarousel from 'embla-carousel-react';
import usePagination from '@/hooks/usePagination';
import { twMerge } from 'tailwind-merge';

export interface IBlogCarousel extends Partial<ICMSCompatibleComponent> {
  blogs?: IBlog[];
  limit?: number;
}

export default function BlogCarouselClient(props: IBlogCarousel) {
  const { blogs, editable } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 'auto',
  });

  function onPrevButtonClick() {
    emblaApi?.scrollPrev();
  }

  function onNextButtonClick() {
    emblaApi?.scrollNext();
  }

  const { paginationList, selectedIndex } = usePagination(emblaApi);

  return (
    <div {...editable} ref={emblaRef} className='relative overflow-hidden'>
      <div className='flex will-change-transform sm:-mr-10 lg:-mr-20'>
        {blogs?.map((item, i) => (
          <div
            className='min-w-0 flex-1-per-page cursor-grab *:h-full active:cursor-grabbing sm:flex-2-per-page sm:pr-10 lg:flex-3-per-page lg:pr-20 select-none'
            key={`${i.toString()}`}
          >
            <BlogItem {...item} />
          </div>
        ))}
      </div>
      <div className='mt-12 flex items-center justify-between'>
        <Button
          variant={EButtonVariants.SecondaryLinkFront}
          onClick={onPrevButtonClick}
          buttonType='button'
          className='disabled:cursor-not-allowed'
          disabled={!emblaApi?.canScrollPrev()}
        >
          BACK
        </Button>
        {paginationList.length > 1 && (
          <div data-testid='carousel-pagination' className='hidden w-full justify-center gap-3 sm:flex'>
            {paginationList.map((_, i) => (
              <div
                className={twMerge(
                  'h-3 w-3 rounded-full border-2 border-primary-white transition-all duration-500',
                  i === selectedIndex && 'bg-primary-white'
                )}
                key={`${i.toString()}`}
              />
            ))}
          </div>
        )}
        <Button
          variant={EButtonVariants.SecondaryLink}
          onClick={onNextButtonClick}
          buttonType='button'
          className='disabled:cursor-not-allowed'
          disabled={!emblaApi?.canScrollNext()}
        >
          NEXT
        </Button>
      </div>
      {!blogs?.length && (
        <p className='col-span-3 my-6 flex h-40 flex-col items-center text-center'>
          <DatabaseSlash width={60} height={60} />
          <span className='mt-6'>No data to display.</span>
        </p>
      )}
    </div>
  );
}
